@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    display: none;
}

html {
    background-color: black;
}

#name::placeholder {
    color: #010101;
    /* Change this to your desired color */
}

#email::placeholder {
    color: #010101;
    /* Change this to your desired color */
}

#message::placeholder {
    color: #010101;
    /* Change this to your desired color */
}